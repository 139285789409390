import React, {useEffect} from 'react'
import './main.css'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {HiClipboardList} from 'react-icons/hi'
import img from '../../Assets/img (12).jpg'
import img2 from '../../Assets/img (2).jpg'
import img3 from '../../Assets/img (3).jpg'
import img4 from '../../Assets/img (4).jpg'
import img5 from '../../Assets/img (13).jpg'
import img6 from '../../Assets/img (6).jpg'
import img7 from '../../Assets/img (7).jpg'
import img8 from '../../Assets/img (14).jpg'
import img9 from '../../Assets/img (9).jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'



const Data = [
  {
  id:1,
  imgSrc: img,
  destTitle: 'Leh',
  location: 'Ladakh',
  grade: 'ADVENTURE',
  fees: '₹₹₹',
  description: 'Discover the enchanting beauty of Leh with our guided tours, offering a thrilling mix of adventure, culture, and breathtaking natural landscapes.' 
  },


  {
  id:2,
  imgSrc: img2,
  destTitle: 'Kashmir',
  location: 'Jammu & Kashmir',
  grade: 'CULTURAL RELAX',
  fees: '₹₹₹',
  description: 'Kashmir is a picturesque region in northern India, famed for its stunning natural beauty, rich culture, and adventurous activities.' 
  },


  {
  id:3,
  imgSrc: img3,
  destTitle: 'Goa',
  location: 'Goa',
  grade: 'Sea',
  fees: '₹₹₹',
  description: 'Goa is a coastal paradise in India, famous for its beaches, seafood, nightlife, and Portuguese colonial architecture.'  
  },


  {
  id:4,
  imgSrc: img4,
  destTitle: 'Jaisalmer',
  location: 'Rajasthan',
  grade: 'CULTURAL RELAX ',
  fees: '₹₹₹',
  description: 'Jaisalmer, "The Golden City," in India s Rajasthan state, boasts stunning sandstone architecture, a vibrant culture, and mesmerizing desert landscapes. '  
  },


  {
  id:5,
  imgSrc: img5,
  destTitle: 'Kerala',
  location: 'Kerala',
  grade: 'SOUTH INDIA ',
  fees: '₹₹₹',
  description: 'Discover the serene backwaters, lush tea plantations, pristine beaches, and vibrant culture of Kerala, India s tropical paradise.'  
  },


  {
  id:6,
  imgSrc: img6,
  destTitle: 'Manali',
  location: 'Himachal Pradesh',
  grade: 'HIMALAYAS',
  fees: '₹₹₹',
  description: 'Manali is a picturesque hill station in northern India known for its scenic beauty, adventure sports, and vibrant culture.'  
  },


  {
  id:7,
  imgSrc: img7,
  destTitle: 'Shimla',
  location: 'Himachal Pradesh',
  grade: 'NATURAL RELAX',
  fees: '₹₹₹',
  description: 'Explore the scenic hill station of Shimla, nestled in the Himalayas, offering colonial architecture, stunning views and vibrant culture.'  
  },


  {
  id:8,
  imgSrc: img8,
  destTitle: 'Jibhi',
  location: 'Himachal Pradesh',
  grade: 'RELAX',
  fees: '₹₹₹',
  description: 'Jibhi: A picturesque hamlet in Himachal Pradesh, India, nestled in lush green forests and surrounded by majestic mountains.' 
  },


  {
  id:9,
  imgSrc: img9,
  destTitle: 'Tirthan',
  location: 'Himachal Pradesh',
  grade: 'NATURAL AURA',
  fees: '₹₹₹',
  description: 'Tirthan is a scenic valley in Himachal Pradesh, India, known for its crystal-clear river, lush forests, and stunning mountain views.' 
  },
]

const Main = () => {
  useEffect(()=>{
    Aos.init({duration: 4000})
 }, [])
  return (
    <section id='main' className='main section container'>
      <div className="secTitle">
        <h3 className="title">
          Most visited destinations
        </h3>
      </div>

      <div className="secContent grid">
        {
          Data.map(({id, imgSrc, destTitle, location, grade, fees, description})=>{
            return (
              
              <div key={id} data-aos="fade-up" className="singleDestination">
      
                 <div className="imageDiv">
                 <img src={imgSrc} alt="" />
                 </div>
      
                <div className="cardInfo">
                 <h4 className="destTitle">{destTitle}</h4>
                 <span className="continent flex">
                    <HiOutlineLocationMarker className="icon"/>
                    <span className="name">{location}</span>
                 </span>
      
                 <div className="fees flex">
                    <div className="grade">
                      <span>{grade}<small>+1 </small> </span>
                    </div>
                    <div className="price">
                      <h5>{fees}</h5>
                    </div>
                 </div>
      
                 <div className="desc">
                  <p>{description}</p>
                 </div>
      
                 <button className='btn flex'>DETAILS <HiClipboardList className="icon"/> </button>
                </div>
              </div>
      
            )
          }) 
        }
      </div>
     
    </section>
  )
}

export default Main